<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="col">
                    <h3 class="card-title">
                        <i class="ti ti-cpu"></i>
                        Filas internas
                    </h3>
                    <p class="text-muted">
                        Gestão de filas internas do workspace com Redis
                    </p>
                </div>
                <div class="col-auto">
                    <a
                        id="newQueueButton"
                        href="/queues/new"
                        class="btn btn-primary"
                    >
                        <i class="ti ti-plus"></i>
                        Criar nova fila
                    </a>
                </div>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-3 mb-2">
                        <input
                            id="searchQueuesButton"
                            v-model="filter.query"
                            type="text"
                            class="form-control"
                            placeholder="Buscar filas"
                            @change="updateFilter"
                        />
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div v-if="queueList.length">
                    <table class="table table-vcenter card-table">
                        <thead>
                            <tr>
                                <th>Nome da fila</th>
                                <th>Status</th>
                                <th>Grupo</th>
                                <th>Criação</th>
                                <th>Atualização</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="queue in queueList" :key="queue._id">
                                <td>{{ queue.name }}</td>
                                <td>
                                    <button
                                        v-if="queue.status == 'paused'"
                                        class="btn btn-sm startConsumptionButton"
                                        @click="resume(queue._id)"
                                    >
                                        <i class="ti ti-player-play-filled"></i>
                                        Iniciar consumo
                                    </button>
                                    <button
                                        v-if="queue.status == 'running'"
                                        class="btn btn-sm pauseConsumptionButton"
                                        @click="pause(queue._id)"
                                    >
                                        <i
                                            class="ti ti-player-pause-filled"
                                        ></i>
                                        Pausar consumo
                                    </button>
                                    <span>&nbsp;</span>
                                    <span
                                        v-if="queue.status == 'running'"
                                        class="badge bg-green text-green-fg"
                                        >{{ queue.status }}</span
                                    >
                                    <span
                                        v-if="queue.status == 'paused'"
                                        class="badge bg-azure text-white"
                                        >{{ queue.status }}</span
                                    >
                                    <span
                                        v-if="queue.status == 'waiting'"
                                        class="badge bg-orange-lt"
                                        >{{ queue.status }}</span
                                    >
                                </td>
                                <td>
                                    <span
                                        v-if="!groupName(queue.group)"
                                        class="badge bg-gray-lt text-muted"
                                        >Sem grupo</span
                                    >
                                    <span v-else class="badge bg-blue-lt">{{
                                        groupName(queue.group)
                                    }}</span>
                                </td>
                                <td>{{ formatDate(queue.created_at) }}</td>
                                <td>{{ formatDate(queue.updated_at) }}</td>
                                <td>
                                    <span>&nbsp;</span>
                                    <a
                                        :href="'/queues/view/' + queue._id"
                                        class="btn btn-sm showQueueButton"
                                    >
                                        <i class="ti ti-eye"></i>
                                        Visualizar
                                    </a>
                                    <span>&nbsp;</span>
                                    <a
                                        :href="'/queues/edit/' + queue._id"
                                        class="btn btn-sm btn-ghost-secondary editQueueButton"
                                    >
                                        <i class="ti ti-pencil"></i>
                                        Editar
                                    </a>
                                    <span>&nbsp;</span>
                                    <a
                                        :href="'/queues/delete/' + queue._id"
                                        class="btn btn-sm btn-ghost-danger deleteQueueButton"
                                    >
                                        <i class="ti ti-trash"></i>
                                        Excluir
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-if="!queueList.length && !filter.query" class="card-body">
                <div class="empty">
                    <p class="empty-title">Nenhuma fila criada ainda.</p>
                    <p class="empty-subtitle text-muted">
                        Clique no botão abaixo para criar sua primeira fila!
                    </p>
                    <div class="empty-action">
                        <a
                            id="newQueueButton"
                            href="/queues/new"
                            class="btn btn-primary"
                        >
                            <i class="ti ti-plus"></i>
                            Criar fila
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="!queueList.length && filter.query" class="card-body">
                <div class="empty bg-muted-lt">
                    <p class="empty-title">
                        Nenhuma fila encontrada para esta busca.
                    </p>
                </div>
            </div>

            <div class="card-footer">
                <Pagination
                    v-if="total > showPerPage"
                    :key="paginationKey"
                    :show-per-page="showPerPage"
                    :current-page="currentPage"
                    :total="total"
                    @page-change="updatePage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store"
import moment from "moment"
import Api from "../services/api"
import Pagination from "./Pagination"
import EventBus from "../services/event-bus"

export default {
    name: "QueuesList",
    components: {
        Pagination
    },
    data() {
        return {
            queueList: [],
            filter: {
                query: ""
            },
            paginationKey: 0,
            showPerPage: 10,
            currentPage: 1,
            total: 0
        }
    },
    async mounted() {
        this.currentPage = parseInt(this.$route.query.page) || 1
        this.filter.query = this.$route.query.query || ""
        this.filter.group = localStorage.getItem("selectedGroup") || ""

        this.loadQueues()

        EventBus.on("update-selected-group", () => {
            this.filter.group = localStorage.getItem("selectedGroup") || ""
            this.updateFilter()
        })
    },
    methods: {
        async loadQueues() {
            this.paginationKey = Math.random()

            const response = await Api.queues.list(
                this.currentPage,
                this.showPerPage,
                this.filter
            )

            this.queueList = response.data.items
            this.total = response.data.total
        },
        async updatePage(page) {
            this.$router.replace({
                query: {
                    page: page,
                    query: this.filter.query,
                    _: Math.random()
                }
            })

            this.currentPage = page
        },
        updateFilter() {
            this.$router.replace({
                query: {
                    page: 1,
                    query: this.filter.query,
                    _: Math.random()
                }
            })
        },
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss")
        },
        async pause(queueId) {
            try {
                await Api.queues.pause(queueId)
                await this.loadQueues()
            } catch (error) {
                console.log(error)
            }
        },
        async resume(queueId) {
            try {
                await Api.queues.resume(queueId)
                await this.loadQueues()
            } catch (error) {
                console.log(error)
            }
        },
        groupName(group_id) {
            if (!group_id) return null

            var group = null
            store.groups.forEach((item) => {
                if (item._id == group_id) {
                    group = item
                }
            })

            return group ? group.name : null
        }
    }
}
</script>
