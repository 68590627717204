const translations = {
    "pt-BR": {
        "Thumbnail data is too large. Please send max %s.":
            "Imagem gerada pelo thumbnail está muito grande (Máx. de %s).",
        "This flow contains deprecated nodes. Please remove them before creating a snapshot.":
            "O fluxo contém um ou mais conectores que serão removidos em breve. Por favor, remova-os antes de salvar o fluxo.",
        "DeprecatedNodeError: Some nodes are deprecated:%s": "Alguns conectores estão obsoletos:\n\n%s",
        "Request failed with status code 404": "O recurso solicitado não foi encontrado.",
        "Datasource not found": "Datasource não encontrado.",
        Error: "Erro",
        Warning: "Aviso",
        Info: "Aviso",
        Success: "Sucesso",
        "Error creating snapshot": "Erro ao criar snapshot"
    }
}

function translateWithPlaceholders(phrase, translations) {
    // verifica primeiro match exato de frases
    if (translations[phrase]) {
        return translations[phrase]
    }

    // Itera sobre as traduções para encontrar a correspondência mais próxima
    for (const [key, value] of Object.entries(translations)) {
        // Cria uma expressão regular para lidar com o %s como um curinga
        const regex = new RegExp(`^${key.replace("%s", "(.*)")}$`, "s")

        // Verifica se a frase corresponde à chave de tradução
        const match = phrase.match(regex)
        if (match && match[1]) {
            // Substitui o %s no valor traduzido com o valor encontrado
            return value.replace("%s", match[1].trim())
        }
    }

    // Retorna a frase original se nenhuma tradução for encontrada
    return phrase
}

export default function (str, lang = "pt-BR") {
    if (translations[lang] == undefined) {
        console.error("Translation not found for language:", lang)
        return str
    }

    return translateWithPlaceholders(str, translations[lang])
}
