<template>
    <div class="page">
        <aside
            v-if="store.showSidebar"
            class="navbar navbar-vertical navbar-expand-sm navbar-dark"
            data-bs-theme="dark"
            :class="store.sidebarSize"
        >
            <div class="container-fluid">
                <button
                    id="navBarToggleButton"
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbar-menu"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <SidebarMenu />
            </div>
        </aside>
        <div class="page-wrapper">
            <Header v-if="store.showHeader" />
            <Toast position="top-center" />
            <ConfirmDialog />
            <div class="container-fluid mt-2">
                <router-view :key="$route.fullPath"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "./components/Header.vue"
import SidebarMenu from "./components/SidebarMenu.vue"
import EventBus from "./services/event-bus"
import $t from "./services/i18n"
import { store } from "./store"

import Toast from "primevue/toast"
import ConfirmDialog from "primevue/confirmdialog"

export default {
    name: "App",
    components: {
        Header,
        SidebarMenu,
        Toast,
        ConfirmDialog
    },
    data() {
        return {
            store
        }
    },
    mounted() {
        EventBus.on("message", (message) => {
            let severity = this.parseSeverity(message.type)
            let text = message.message

            this.$toast.add({
                severity: severity,
                summary: $t(severity.charAt(0).toUpperCase() + severity.slice(1)),
                detail: text,
                life: 3000
            })
        })

        EventBus.on("clear-messages", () => {
            this.$toast.removeAll()
        })
    },
    methods: {
        parseSeverity(t) {
            switch (t) {
                case "danger":
                    return "error"
                case "warning":
                    return "warn"
            }
            return t
        }
    }
}
</script>
