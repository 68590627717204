<template>
    <div>
        <div id="modal-flow-versions" class="modal modal-blur fade" tabindex="-1" aria-modal="true" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Histórico de versões</h5>
                        <button
                            id="closeFormButton"
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body pt-2">
                        <div class="modal-body-container">
                            <div v-if="dataHistory.length" class="list-container">
                                <div
                                    v-for="(item, index) in dataHistory"
                                    :key="index"
                                    class="list-item"
                                    @mouseover="item.showRestore = true"
                                    @mouseleave="item.showRestore = false"
                                >
                                    <div class="item-wrapper">
                                        <div class="left-info">
                                            <span>
                                                <i class="ti ti-device-floppy text-muted"></i>
                                                {{ item.version_number }}
                                            </span>

                                            <span
                                                v-if="!item.isEditing && item.version_name"
                                                class="badge bg-blue-lt text-ellipsis ml-2"
                                            >
                                                <strong>{{ item.version_name || "" }}</strong>
                                            </span>

                                            <div v-if="item.isEditing" class="edit-container">
                                                <input
                                                    v-model="item.newName"
                                                    class="edit-input"
                                                    @keyup.enter="confirmEdit(item)"
                                                />
                                                <button
                                                    class="confirm-btn ti ti-check"
                                                    @click.stop="confirmEdit(item)"
                                                ></button>
                                            </div>

                                            <span v-if="item.current" class="badge bg-green-lt ml-2">
                                                versão atual
                                            </span>
                                        </div>
                                        <div class="right-info">
                                            <small
                                                class="text-muted show-popover"
                                                data-bs-container="body"
                                                data-bs-toggle="popover"
                                                data-bs-placement="bottom"
                                                :data-bs-content="`${item.author_name} (${item.author_email})`"
                                                >{{ formatDate(item.updated_at) }}</small
                                            >
                                        </div>

                                        <div class="dropdown">
                                            <button
                                                class="btn btn-md tableOptionsDropdown m-0 p-1"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Opções
                                                <i class="ti ti-chevron-down m-0 p-0"></i>
                                            </button>
                                            <ul class="dropdown-menu p-2">
                                                <li>
                                                    <a
                                                        class="dropdown-item"
                                                        role="button"
                                                        @click="emitRestore(item._id)"
                                                        >Restaurar</a
                                                    >
                                                </li>
                                                <li v-if="item.type === 'snapshot'">
                                                    <a class="dropdown-item" role="button" @click="startEditing(item)"
                                                        >Alterar nome</a
                                                    >
                                                </li>
                                                <li v-if="item.type === 'snapshot'">
                                                    <a class="dropdown-item" role="button" @click="emitDelete(item._id)"
                                                        >Deletar</a
                                                    >
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="list-container centerContainer">
                                <p class="text-medium-emphasis m-0">Histórico Vazio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone"
import EventBus from "../services/event-bus"

export default {
    name: "FlowVersionModal",
    props: {
        dataHistory: {
            type: Array,
            required: true
        }
    },
    emits: ["restoreVersion", "updateName", "deleteSnapshot"],
    data() {
        return {
            localDialogHistory: this.showDialogHistory
        }
    },
    mounted() {
        var modal = new bootstrap.Modal(document.getElementById("modal-flow-versions"), { keyboard: false })

        const el = document.querySelectorAll(".show-popover")
        el.forEach(
            (el) =>
                new bootstrap.Popover(el, {
                    container: "body",
                    trigger: "hover"
                })
        )

        modal.show()
    },
    methods: {
        startEditing(item) {
            item.isEditing = true
            item.newName = item.version_name
        },
        confirmEdit(item) {
            const { isValid, message } = this.validateField(item.newName)
            if (!isValid) {
                item.newName = item.version_name
                EventBus.emit("message", {
                    type: "danger",
                    message
                })
                return
            }
            item.newName = item.newName.trim()
            item.version_name = item.newName

            this.$emit("updateName", item._id, item.newName)

            item.isEditing = false
        },
        emitRestore(versionId) {
            this.$emit("restoreVersion", versionId)
        },
        emitDelete(versionId) {
            const isConfirmed = confirm("Deseja realmente deletar esse snapshot? Essa ação é irreversível!")
            if (isConfirmed) {
                this.$emit("deleteSnapshot", versionId)
            }
        },
        formatDate(date) {
            moment.locale("pt-br")
            return moment(date).format("DD/MM/YYYY HH:mm:ss")
        },
        validateField(field) {
            if (field === undefined || field === null || field.trim().length <= 0) {
                return { isValid: false, message: "Nome é obrigatório." }
            }
            if (field.trim().length > 60) {
                return {
                    isValid: false,
                    message: "Nome deve ter no máximo 60 caracteres."
                }
            }
            return { isValid: true, message: "" }
        }
    }
}
</script>

<style scoped>
.list-container {
    width: 100%;
    height: 300px;
    overflow-y: auto;
}

.list-item {
    padding: 2px 10px;
}

.centerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    border-bottom: 1px solid #ccc;
    /* border-radius: 5px; */
    margin-bottom: 2px;
    position: relative;
    transition: all 0.5s ease;
    cursor: pointer;
}

.left-info {
    display: flex;
    gap: 2px;
    flex-grow: 1;
}

.right-info {
    text-align: right;
    margin-right: 25px;
}

.text-ellipsis {
    margin-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 210px;
    display: inline-block;
}

.edit-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.edit-input {
    margin-bottom: 3px;
    height: 22px;
    padding: 1px 5px;
    font-size: 14px;
    width: 210px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.confirm-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 0px 6px;
    height: 22px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s;
    margin-bottom: 3px;
}

.confirm-btn:hover {
    background-color: #45a049;
}
</style>
