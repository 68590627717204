<template>
    <div v-show="path.length > 0" class="card">
        <div class="card-body">
            <ol class="breadcrumb">
                <li v-for="item in path" :key="item.title" class="breadcrumb-item" :class="{ active: item.active }">
                    <a v-if="item.href && !item.active" :href="item.href">
                        <span v-if="item.root">
                            <i class="ti ti-home"></i>
                        </span>
                        <span v-else>{{ item.title }}</span>
                    </a>
                    <span v-else>{{ item.title }}</span>
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
export default {
    name: "BreadCrumb",
    props: {
        path: {
            type: Object[{ title: String, href: String, disabled: Boolean }],
            default: []
        }
    }
}
</script>
