<template>
    <div>
        <div>
            <div class="page-wrapper">
                <div class="page-body">
                    <div class="row g-2">
                        <div class="col">
                            <div class="card">
                                <div class="card-header">
                                    <div class="col">
                                        <h3 class="card-title">
                                            <i class="ti ti-folders"></i>
                                            Grupos
                                        </h3>
                                    </div>
                                    <div class="col-auto">
                                        <a
                                            href="/groups/new"
                                            class="btn btn-primary"
                                        >
                                            <i class="ti ti-plus"></i>
                                            Criar grupo
                                        </a>
                                    </div>
                                </div>

                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-3 mb-2">
                                            <input
                                                v-model="filter.query"
                                                type="text"
                                                class="form-control"
                                                placeholder="Buscar grupos"
                                                @change="updateFilter"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div v-if="groupsList.length" class="table">
                                    <table
                                        class="table table-vcenter card-table"
                                    >
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Criação</th>
                                                <th>Atualização</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="alertRule in groupsList"
                                                :key="alertRule._id"
                                            >
                                                <td>
                                                    {{ alertRule.name }}
                                                </td>
                                                <td>
                                                    {{
                                                        formatDate(
                                                            alertRule.created_at
                                                        )
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        formatDate(
                                                            alertRule.updated_at
                                                        )
                                                    }}
                                                </td>
                                                <td>
                                                    <span class="dropdown">
                                                        <button
                                                            class="btn btn-sm dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                        >
                                                            Opções
                                                        </button>
                                                        <div
                                                            class="dropdown-menu"
                                                        >
                                                            <a
                                                                :href="
                                                                    '/groups/edit/' +
                                                                    alertRule._id
                                                                "
                                                                class="dropdown-item"
                                                            >
                                                                <i
                                                                    class="ti ti-pencil"
                                                                ></i>
                                                                Editar
                                                            </a>
                                                            <a
                                                                :href="
                                                                    '/groups/delete/' +
                                                                    alertRule._id
                                                                "
                                                                class="dropdown-item bg-danger-lt"
                                                            >
                                                                <i
                                                                    class="ti ti-trash"
                                                                ></i>
                                                                Remover
                                                            </a>
                                                        </div>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div
                                    v-if="!groupsList.length && !filter.query"
                                    class="card-body"
                                >
                                    <div class="empty">
                                        <p class="empty-title">
                                            Nenhum grupo criado ainda.
                                        </p>
                                        <p class="empty-subtitle text-muted">
                                            Clique no botão abaixo para criar
                                            sua primeiro grupo personalizada!
                                        </p>
                                        <div class="empty-action">
                                            <a
                                                href="/groups/new"
                                                class="btn btn-primary"
                                            >
                                                <i class="ti ti-plus"></i>
                                                Criar grupo
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-if="!groupsList.length && filter.query"
                                    class="card-body"
                                >
                                    <div class="empty bg-muted-lt">
                                        <p class="empty-title">
                                            Nenhum grupo encontrado para esta
                                            busca.
                                        </p>
                                    </div>
                                </div>

                                <div class="card-footer">
                                    <Pagination
                                        v-if="total"
                                        :key="paginationKey"
                                        :show-per-page="showPerPage"
                                        :current-page="currentPage"
                                        :total="total"
                                        @page-change="updatePage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "../services/event-bus"
import Api from "../services/api"
import { store } from "../store"
import Pagination from "../components/Pagination"
import moment from "moment"

export default {
    name: "GroupsPage",
    components: {
        Pagination
    },
    data() {
        return {
            paginationKey: null,
            showPerPage: 10,
            currentPage: 1,
            total: 0,
            groupsList: [],
            filter: {
                query: ""
            }
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true
    },
    async mounted() {
        this.currentPage = parseInt(this.$route.query.page) || 1
        this.filter.query = this.$route.query.query || ""
        this.loadGroups()
    },
    methods: {
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss")
        },
        async loadGroups() {
            this.paginationKey = Math.random()

            var response = await Api.groups.list(
                this.currentPage,
                this.showPerPage,
                this.filter
            )

            this.groupsList = response.data.items
            this.total = response.data.total
        },
        updateFilter() {
            this.$router.replace({
                query: {
                    page: 1,
                    query: this.filter.query,
                    _: Math.random()
                }
            })
        },

        updatePage(page) {
            this.currentPage = page
            this.paginationKey = Math.random()

            var queryParams = {
                page: page
            }

            if (this.filter.query) queryParams.query = this.filter.query

            this.$router.replace({
                query: queryParams,
                _: Math.random()
            })
        }
    }
}
</script>
