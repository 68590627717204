<template>
    <div>
        <div
            id="modal-service-account-form"
            class="modal modal-blur fade"
            tabindex="-1"
            style="display: none"
            aria-modal="true"
            role="dialog"
        >
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ actionTitle }}</h5>
                        <button
                            id="closeFormButton"
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <form autocomplete="off" aria-autocomplete="off">
                        <div class="modal-body">
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Nome</label>
                                        <input
                                            id="serviceAccountNameInput"
                                            v-model="serviceAccount.name"
                                            type="text"
                                            class="form-control"
                                            placeholder="Nome da sua conta de serviço"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required">Tipo de autenticação</label>
                                        <select
                                            id="serviceAccountTypeSelect"
                                            v-model="serviceAccount.auth_type"
                                            class="form-select"
                                        >
                                            <option value="bearer_token">Bearer Token</option>
                                            <option value="http_basic">HTTP Basic</option>
                                            <option value="jwt">JWT</option>
                                            <option value="smtp_account">SMTP Account</option>
                                            <option value="database_uri">Database Connection URI</option>
                                            <option value="oauth2">OAuth2</option>
                                            <option value="trello_oauth2">Trello</option>
                                            <option value="slack_oauth2">Slack</option>
                                            <option value="google_oauth2">Google</option>
                                            <option value="aws_access_key">AWS (Access Key)</option>
                                            <option value="floui_token">Floui Token</option>
                                            <option value="sankhya_partner_id">Sankhya ID (Parceiro Sankhya)</option>
                                            <option value="omie_erp">Omie ERP</option>
                                            <option value="ftp_server">FTP Server</option>
                                            <option value="gpg_auth">GPGAuth</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="serviceAccount.auth_type == 'http_basic'" class="modal-body">
                            <div class="mb-3">
                                <label class="form-label required">URL do serviço</label>
                                <input
                                    id="serviceAccountUrlInput"
                                    v-model="serviceAccount.url"
                                    type="text"
                                    class="form-control"
                                    placeholder="Exemplo: https://seu-servico.com.br/rest"
                                />
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Usuário</label>
                                        <input
                                            id="serviceAccountUsernameInput"
                                            v-model="http_basic.username"
                                            type="text"
                                            class="form-control"
                                            placeholder="Usuário"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required">Senha</label>
                                        <input
                                            id="serviceAccountPasswordInput"
                                            v-model="http_basic.password"
                                            type="password"
                                            class="form-control"
                                            placeholder="Senha"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="serviceAccount.auth_type == 'aws_access_key'" class="modal-body">
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Access Key ID</label>
                                        <input
                                            id="serviceAccountAccessKeyIdInput"
                                            v-model="aws_access_key.access_key_id"
                                            type="text"
                                            class="form-control"
                                            placeholder="Usuário"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required">Secret Access Key</label>
                                        <input
                                            id="serviceAccountSecretAccessKeyInput"
                                            v-model="aws_access_key.secret_access_key"
                                            type="password"
                                            class="form-control"
                                            placeholder="Senha"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="serviceAccount.auth_type == 'sankhya_partner_id'" class="modal-body">
                            <div class="mb-3">
                                <div class="row mb-3">
                                    <div class="col">
                                        <label class="form-label required">AppKey de Parceiro Sankhya</label>
                                        <input
                                            id="serviceAccountSankhyaPartnerIdAppKeyInput"
                                            v-model="sankhya_partner_id.appkey"
                                            type="text"
                                            class="form-control"
                                            placeholder="AppKey"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required">Token</label>
                                        <input
                                            id="serviceAccountSankhyaPartnerIdTokenInput"
                                            v-model="sankhya_partner_id.token"
                                            type="token"
                                            class="form-control"
                                            placeholder="Token"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Usuário</label>
                                        <input
                                            id="serviceAccountSankhyaPartnerIdUsernameInput"
                                            v-model="sankhya_partner_id.username"
                                            type="text"
                                            class="form-control"
                                            placeholder="Usuário"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required">Senha</label>
                                        <input
                                            id="serviceAccountSankhyaPartnerIdPasswordInput"
                                            v-model="sankhya_partner_id.password"
                                            type="password"
                                            class="form-control"
                                            placeholder="Senha"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="serviceAccount.auth_type == 'omie_erp'" class="modal-body">
                            <div class="mb-3">
                                <div class="row mb-3">
                                    <div class="col">
                                        <label class="form-label required">App Key</label>
                                        <input
                                            id="serviceAccountOmieErpAppKeyInput"
                                            v-model="omie_erp.app_key"
                                            type="text"
                                            class="form-control"
                                            placeholder="App Key"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required">App Secret</label>
                                        <input
                                            id="serviceAccountOmieErpAppSecretInput"
                                            v-model="omie_erp.app_secret"
                                            type="password"
                                            class="form-control"
                                            placeholder="App Secret"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="serviceAccount.auth_type == 'jwt'" class="modal-body">
                            <div class="mb-3">
                                <label class="form-label required">URL do serviço</label>
                                <input
                                    id="serviceAccountUrlInput"
                                    v-model="serviceAccount.url"
                                    type="text"
                                    class="form-control"
                                    placeholder="Exemplo: https://seu-servico.com.br/rest"
                                />
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">URL de autenticação</label>
                                        <input
                                            id="serviceAccountJwtAuthUrlInput"
                                            v-model="jwt.auth_url"
                                            type="text"
                                            class="form-control"
                                            placeholder="Exemplo: https://seuservico.com.br/auth"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">HTTP Method</label>
                                        <select id="jwtMethodSelect" v-model="jwt.method" class="form-select">
                                            <option value="POST">POST</option>
                                            <option value="PUT">PUT</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <label class="form-label required">TTL / Expiration (seconds)</label>
                                        <input id="jwtTtlInput" v-model="jwt.ttl" type="text" class="form-control" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Usuário</label>
                                        <input
                                            id="serviceAccountJwtUsernameInput"
                                            v-model="jwt.username"
                                            type="text"
                                            class="form-control"
                                            placeholder="Usuário"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required">Senha</label>
                                        <input
                                            id="serviceAccountJwtPasswordInput"
                                            v-model="jwt.password"
                                            type="password"
                                            class="form-control"
                                            placeholder="Senha"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="serviceAccount.auth_type == 'oauth2'" class="modal-body">
                            <oauth2-service-account v-model:service-account="serviceAccount" v-model:oauth2="oauth2" />
                        </div>

                        <div v-if="serviceAccount.auth_type == 'bearer_token'" class="modal-body">
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Token de autenticação</label>
                                        <input
                                            id="serviceAccountBearerTokenInput"
                                            v-model="bearer_token.token"
                                            type="password"
                                            class="form-control"
                                            placeholder="*******"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">URL do serviço</label>
                                <input
                                    id="serviceAccountUrlInput"
                                    v-model="serviceAccount.url"
                                    type="text"
                                    class="form-control"
                                    placeholder="Exemplo: https://seu-servico.com.br/rest"
                                />
                                <p class="form-hint">
                                    Obrigatório para alguns conectores, como REST API e Magento/Adobe Commerce.
                                </p>
                            </div>
                        </div>

                        <div v-if="serviceAccount.auth_type == 'floui_token'" class="modal-body">
                            <div class="mb-3">
                                <label class="form-label required">Token (gerado automaticamente)</label>
                                <input
                                    id="serviceAccountFlouiTokenInput"
                                    v-model="floui_token.token"
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                    readonly="readonly"
                                />
                            </div>
                        </div>

                        <div v-if="serviceAccount.auth_type == 'database_uri'" class="modal-body">
                            <div class="mb-3">
                                <label class="form-label required">URI de conexão</label>
                                <input
                                    id="serviceAccountDatabaseUriInput"
                                    v-model="database_uri.uri"
                                    type="text"
                                    class="form-control"
                                    placeholder="mysql://user:password@host:port/database"
                                />
                            </div>
                        </div>

                        <div v-if="serviceAccount.auth_type == 'smtp_account'" class="modal-body">
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Host SMTP</label>
                                        <input
                                            id="serviceAccountSmtpHostInput"
                                            v-model="smtp_account.host"
                                            type="text"
                                            class="form-control"
                                            placeholder="Exemplo: smtp.gmail.com"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Porta</label>
                                        <input
                                            id="serviceAccountSmtpPortInput"
                                            v-model="smtp_account.port"
                                            type="text"
                                            class="form-control"
                                            placeholder="587"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Usuário</label>
                                        <input
                                            id="serviceAccountSmtpUsernameInput"
                                            v-model="smtp_account.username"
                                            type="text"
                                            class="form-control"
                                            placeholder="Usuário"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required">Senha</label>
                                        <input
                                            id="serviceAccountSmtpPasswordInput"
                                            v-model="smtp_account.password"
                                            type="password"
                                            class="form-control"
                                            placeholder="Senha"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="serviceAccount.auth_type == 'ftp_server'" class="modal-body">
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col-3">
                                        <label class="form-label required">Tipo de conexão</label>
                                        <select
                                            id="ftpServerTypeSelect"
                                            v-model="ftp_server.server_type"
                                            class="form-select"
                                        >
                                            <option value="FTP">FTP</option>
                                            <option value="FTPS">FTP over TLS</option>
                                        </select>
                                    </div>
                                    <div class="col-9">
                                        <label class="form-label required">Host</label>
                                        <input
                                            id="ftpServerHostInput"
                                            v-model="ftp_server.host"
                                            type="text"
                                            class="form-control"
                                            placeholder="Exemplo: ftp.dominio.com"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Porta</label>
                                        <input
                                            id="ftpServerPortInput"
                                            v-model="ftp_server.port"
                                            type="text"
                                            class="form-control"
                                            placeholder="21"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Usuário</label>
                                        <input
                                            id="ftpServerUsernameInput"
                                            v-model="ftp_server.username"
                                            type="text"
                                            class="form-control"
                                            placeholder="Usuário"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required">Senha</label>
                                        <input
                                            id="ftpServerPasswordInput"
                                            v-model="ftp_server.password"
                                            type="password"
                                            class="form-control"
                                            placeholder="Senha"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="serviceAccount.auth_type == 'gpg_auth'" class="modal-body">
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">URL do serviço</label>
                                        <input
                                            id="gpgAuthServiceUrl"
                                            v-model="serviceAccount.url"
                                            type="text"
                                            class="form-control"
                                            placeholder="Exemplo: https://seu-servico.com.br"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Public key</label>
                                        <textarea
                                            id="gpgAuthPublicKey"
                                            v-model="gpg_auth.public_key"
                                            class="form-control"
                                            placeholder="-----BEGIN PGP PUBLIC KEY BLOCK-----"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Private key</label>
                                        <textarea
                                            id="gpgAuthPrivateKey"
                                            v-model="gpg_auth.private_key"
                                            class="form-control"
                                            placeholder="-----BEGIN PGP PRIVATE KEY BLOCK-----"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Passphrase</label>
                                        <input
                                            id="gpgAuthPassphrase"
                                            v-model="gpg_auth.passphrase"
                                            type="password"
                                            class="form-control"
                                            placeholder="Exemplo: 123456"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label">Fingerprint</label>
                                        <input
                                            id="gpgAuthFingerprint"
                                            v-model="gpg_auth.fingerprint"
                                            type="password"
                                            class="form-control"
                                            placeholder="Exemplo: 80FDFA7942DFAS12878DFAAFD12AQ"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-check form-switch">
                                            <input
                                                id="gpgAuthVerifyServerSignature"
                                                v-model="gpg_auth.verify_server_signature"
                                                class="form-check-input"
                                                type="checkbox"
                                            />
                                            <span class="form-check-label">Verificar assinatura do servidor?</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="modal-footer">
                        <button
                            id="cancelButton"
                            class="btn btn-link link-secondary"
                            data-bs-dismiss="modal"
                            @click="cancel()"
                        >
                            Cancelar
                        </button>
                        <button
                            id="saveAccountServiceButton"
                            class="btn btn-primary ms-auto"
                            data-bs-dismiss="modal"
                            @click="save()"
                        >
                            <i class="ti ti-plus"></i>
                            Salvar conta de serviço
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import oauth2ServiceAccount from "@/components/service-accounts/OAuth2ServiceAccount.vue"

export default {
    name: "ServiceAccountForm",

    components: {
        oauth2ServiceAccount
    },
    props: {
        action: {
            type: String,
            required: true,
            default: "create"
        },
        formData: {
            type: Object,
            required: false,
            default: null
        }
    },
    emits: ["cancel", "save"],

    data() {
        return {
            actionTitle: "Criar conta de serviço",
            serviceAccount: {
                auth_type: "bearer_token"
            },
            database_uri: {
                uri: ""
            },
            bearer_token: {
                token: ""
            },
            floui_token: {
                token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            },
            jwt: {
                auth_url: "",
                username: "",
                password: "",
                method: "post",
                ttl: "3600"
            },
            smtp_account: {
                host: "",
                username: "",
                password: "",
                port: "",
                secure: "1"
            },
            ftp_server: {
                server_type: "FTP",
                host: "",
                port: "",
                username: "",
                password: ""
            },
            http_basic: {
                username: "",
                password: ""
            },
            sankhya_partner_id: {
                appkey: "",
                token: "",
                username: "",
                password: ""
            },
            omie_erp: {
                app_key: "",
                app_secret: ""
            },
            oauth1: {
                consumer_key: "",
                consumer_secret: "",
                request_token_url: "",
                access_token_url: "",
                authorize_url: ""
            },
            oauth2: {
                authorize_url: "",
                grant_type: "client_credentials",
                client_id: "",
                client_secret: "",
                service_type: "custom"
            },
            aws_access_key: {
                access_key_id: "",
                secret_access_key: ""
            },
            gpg_auth: {
                public_key: "",
                private_key: "",
                passphrase: "",
                fingerprint: "",
                verify_server_signature: true
            }
        }
    },

    computed: {
        baseUrl: () => {
            return window.location.origin
        }
    },

    mounted() {
        if (this.formData != {} && this.formData != null) {
            this.serviceAccount = structuredClone(this.formData)

            const isOauth2 = this.serviceAccount?.auth_type?.includes("oauth2")

            if (isOauth2) {
                // keep default values and override with formData
                this.oauth2 = { ...this.oauth2, ...this.serviceAccount.oauth2 }
            } else {
                this[this.serviceAccount.auth_type] = this.serviceAccount[this.serviceAccount.auth_type]
            }

            // populate auxiliary fields
            this[this.serviceAccount.auth_type] = this.serviceAccount[this.serviceAccount.auth_type]
        }

        if (this.action === "edit") {
            this.actionTitle = "Editar conta de serviço"
        }

        var modal = new bootstrap.Modal(document.getElementById("modal-service-account-form"))
        modal.show()
    },
    methods: {
        cancel() {
            this.$emit("cancel")
        },

        save() {
            this.serviceAccount = this.clearAuthFields(this.serviceAccount)

            const isOauth2 = this.serviceAccount.auth_type.includes("oauth2")

            if (isOauth2) {
                this.serviceAccount.oauth2 = this.oauth2
            } else {
                this.serviceAccount[this.serviceAccount.auth_type] = this[this.serviceAccount.auth_type]
            }

            console.log(this.serviceAccount)

            this.$emit("save", this.serviceAccount)
        },
        clearAuthFields(object) {
            if (Object.hasOwnProperty("bearer_token")) {
                delete object.bearer_token
            }

            if (Object.hasOwnProperty("database_uri")) {
                delete object.database_uri
            }

            if (Object.hasOwnProperty("floui_token")) {
                delete object.floui_token
            }

            if (Object.hasOwnProperty("jwt")) {
                delete object.jwt
            }

            if (Object.hasOwnProperty("http_basic")) {
                delete object.http_basic
            }

            if (Object.hasOwnProperty("oauth1")) {
                delete object.oauth1
            }

            if (Object.hasOwnProperty("oauth2")) {
                delete object.oauth2
            }

            if (Object.hasOwnProperty("aws_access_key")) {
                delete object.aws_access_key
            }

            if (Object.hasOwnProperty("ftp_server")) {
                delete object.ftp_server
            }

            if (Object.hasOwnProperty("sankhya_partner_id")) {
                delete object.sankhya_partner_id
            }

            if (Object.hasOwnProperty("omie_erp")) {
                delete object.omie_erp
            }

            if (Object.hasOwnProperty("gpg_auth")) {
                delete object.gpg_auth
            }

            return object
        }
    }
}
</script>
