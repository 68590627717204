<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2 mb-3">
                    <div class="col">
                        <div class="form-datasource">
                            <div class="card">
                                <div class="card-header">
                                    <div class="col">
                                        <h3 class="card-title">
                                            <i class="ti ti-database-export"></i>
                                            Editar datasource
                                        </h3>
                                    </div>
                                    <div class="col-auto mx-3">
                                        <div class="input-group">
                                            <span class="input-group-text">
                                                <i class="ti ti-folders"></i>
                                            </span>
                                            <select v-model="datasource.group" class="form-select">
                                                <option :value="null">Sem grupo</option>
                                                <option v-for="group in groupsList" :key="group._id" :value="group._id">
                                                    {{ group.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-primary" @click="saveDatasource()">
                                            <i class="ti ti-device-floppy"></i>
                                            Salvar
                                        </button>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col col-5">
                                                    <label class="form-label required">Nome do datasource</label>
                                                    <input
                                                        v-model="datasource.name"
                                                        type="text"
                                                        class="form-control required"
                                                        placeholder=""
                                                    />
                                                </div>
                                                <div class="col col-2">
                                                    <label class="form-label required">Tipo</label>
                                                    <select v-model="datasource.type" class="form-select required">
                                                        <option value="">Selecione um tipo</option>
                                                        <option value="oracledb">Oracle DB</option>
                                                        <option value="sqlserver">SQL Server</option>
                                                        <option value="mysql">MySQL</option>
                                                        <option value="sqlite">SQLite</option>
                                                        <option value="firebird">Firebird</option>
                                                    </select>
                                                </div>
                                                <div class="col">
                                                    <label class="form-label required">Conta de serviço</label>
                                                    <select
                                                        v-model="datasource.service_account"
                                                        class="form-select required mb-1"
                                                    >
                                                        <option value="">Selecione uma conta de serviço</option>
                                                        <option
                                                            v-for="serviceAccount in serviceAccountListComputed"
                                                            :key="serviceAccount._id"
                                                            :value="serviceAccount._id"
                                                        >
                                                            {{ serviceAccount.name }}
                                                            ({{ serviceAccount.auth_type }})
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="card">
                                            <div class="card-header">
                                                <div class="row">
                                                    <div class="col">
                                                        <ul
                                                            class="nav nav-tabs card-header-tabs"
                                                            data-bs-toggle="tabs"
                                                            role="tablist"
                                                        >
                                                            <li class="nav-item" role="presentation">
                                                                <a
                                                                    href="#tabs-query"
                                                                    class="nav-link active"
                                                                    data-bs-toggle="tab"
                                                                    aria-selected="true"
                                                                    role="tab"
                                                                    tabindex="-1"
                                                                >
                                                                    <i class="ti ti-settings"></i>
                                                                    Configurações da consulta
                                                                </a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a
                                                                    href="#tabs-data-transformation"
                                                                    class="nav-link"
                                                                    data-bs-toggle="tab"
                                                                    aria-selected="false"
                                                                    role="tab"
                                                                >
                                                                    <i class="ti ti-code"></i>
                                                                    Transformação de dados
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="row mb-2">
                                                    <div class="col col-7">
                                                        <div class="tab-content">
                                                            <div
                                                                id="tabs-query"
                                                                class="tab-pane active show"
                                                                role="tabpanel"
                                                            >
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <h4 class="form-label required strong">
                                                                            Query SQL
                                                                        </h4>
                                                                        <div
                                                                            id="monacoEditorContainerQuery"
                                                                            style="height: 300px"
                                                                        ></div>
                                                                        <div
                                                                            class="form-text query-editor-error-message text-danger"
                                                                            style="display: none"
                                                                        >
                                                                            A consulta SQL é obrigatória
                                                                        </div>
                                                                        <div
                                                                            v-show="hasUnsecureSql"
                                                                            class="form-text text-danger"
                                                                        >
                                                                            <i class="ti ti-alert-triangle"></i>
                                                                            A consulta SQL parece conter operações de
                                                                            escrita, e sua execução pode afetar o banco
                                                                            de dados
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="tabs-data-transformation"
                                                                class="tab-pane"
                                                                role="tabpanel"
                                                            >
                                                                <h4 class="form-label required strong">
                                                                    Código Javascript para transformação de dados
                                                                </h4>

                                                                <div
                                                                    id="monacoEditorContainerTransformation"
                                                                    style="height: 300px"
                                                                ></div>
                                                                <div
                                                                    class="form-text transformation-code-error-message text-danger"
                                                                    style="display: none"
                                                                >
                                                                    O código de transformação de dados é obrigatório
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="col">
                                                            <h4>Variáveis de entrada</h4>
                                                            <table
                                                                class="table table-striped table-condensed table-variables"
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th width="30%">Label</th>
                                                                        <th width="25%">Variável</th>
                                                                        <th width="25%">Valor padrão</th>
                                                                        <th>Obrigatório</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr
                                                                        v-for="(
                                                                            row, index
                                                                        ) in datasource.input_variables"
                                                                        :key="row"
                                                                    >
                                                                        <td>
                                                                            <input
                                                                                v-model="row.label"
                                                                                type="text"
                                                                                class="form-control required"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                v-model="row.key"
                                                                                type="text"
                                                                                class="form-control required"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                v-model="row.default_value"
                                                                                type="text"
                                                                                class="form-control"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                v-model="row.required"
                                                                                type="checkbox"
                                                                                class="form-check-input"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                class="btn btn-sm"
                                                                                @click="removeVariable(index)"
                                                                            >
                                                                                <i class="ti ti-trash"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <button class="btn btn-sm" @click="addVariable()">
                                                                <i class="ti ti-plus"></i>
                                                                Adicionar variável
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <button class="btn btn-success" @click="callExecuteQuery()">
                                                            <i class="ti ti-player-play-filled"></i>
                                                            Executar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row g-2">
                    <div class="col">
                        <DatasourceResult
                            v-if="queryResult"
                            :key="queryResultKey"
                            :result="queryResult"
                            :message="queryErrorMessage"
                            class="datasource-result"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div id="modal-input-variables" class="modal modal-blur fade" tabindex="-1" aria-modal="true" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Entre com os valores para a consulta</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body pt-3">
                        <div class="modal-body-container">
                            <div class="row mb-2">
                                <div class="col">
                                    <div
                                        v-for="variable in datasource.input_variables"
                                        :key="variable.key"
                                        class="form-execution-variables"
                                    >
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label
                                                    class="form-label"
                                                    :class="{
                                                        required: variable.required
                                                    }"
                                                    >{{ variable.label }}
                                                    <small class="text-muted">({{ variable.key }})</small></label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    :name="'$input-variable-' + variable.key"
                                                    :value="variable.default_value"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success" @click="executeQuery()">
                            <i class="ti ti-player-play-filled"></i>
                            Executar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as monaco from "monaco-editor/esm/vs/editor/editor.api"
import { limitGroupName, parseValidGroup } from "@/services/utils"
import { store } from "../store"
import Api from "../services/api"
import EngineApi from "../services/engine-api"
import EventBus from "../services/event-bus"
import $t from "../services/i18n"

import DatasourceResult from "../components/DatasourceResult.vue"

const default_transformation_code = `async function transform(data) {
    return data
}`

const default_query_sql = `SELECT * FROM table`

export default {
    name: "DatasourceEditPage",
    components: {
        DatasourceResult
    },
    data() {
        return {
            serviceAccountList: [],
            datasource: {
                name: "Novo datasource",
                type: "",
                service_account: "",
                database_sql: default_query_sql,
                transformation_code: default_transformation_code
            },
            modalInputVariables: null,
            canLeavePage: true,
            queryResult: null,
            queryResultTotal: 0,
            queryResultKey: null
        }
    },
    computed: {
        serviceAccountListComputed() {
            return this.serviceAccountList
                .filter((serviceAccount) => serviceAccount.auth_type === "database_uri")
                .map((serviceAccount) => {
                    return {
                        ...serviceAccount,
                        label: `${serviceAccount.name} (${serviceAccount.auth_type})`
                    }
                })
        },
        selectedServiceAccount() {
            return (
                this.serviceAccountList.find(
                    (serviceAccount) => serviceAccount._id === this.datasource.service_account
                ) || {}
            )
        },
        hasUnsecureSql() {
            let writeOperationWords = [
                "INSERT",
                "UPDATE",
                "DELETE",
                "DROP",
                "CREATE",
                "ALTER",
                "TRUNCATE",
                "GRANT",
                "REVOKE"
            ]
            let query = this.datasource.database_sql.toUpperCase()
            let isWriteOperation = writeOperationWords.some((word) => query.includes(word))
            return isWriteOperation
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true
        store.showBackButton = true

        var groupsResponse = await Api.groups.list(1, 100)
        this.groupsList = groupsResponse.data.items.map((group) => limitGroupName(group))

        var response = await Api.serviceAccounts.list(1, 1000, {
            auth_type: "database_uri"
        })
        this.serviceAccountList = response.data

        // edit
        if (this.$route.params.id) {
            var response = await Api.datasources.get(this.$route.params.id)
            this.datasource = response.data
            this.datasource.group = null
            if (parseValidGroup(this.datasource.group, groupsResponse.data.items)) {
                this.datasource.group = this.datasource.group
            }
        } else {
            const selectedGroup = parseValidGroup(localStorage.getItem("selectedGroup"), this.groupsList)
            this.datasource.group = selectedGroup || null
        }

        this.initQueryEditor()
        this.initTransformationEditor()

        this.modalInputVariables = new bootstrap.Modal(document.getElementById("modal-input-variables"), {
            keyboard: false
        })

        // watch for changes on datasource object
        this.$watch(
            "datasource",
            () => {
                this.canLeavePage = false
            },
            { deep: true }
        )

        // prevent user from leaving the page without saving
        window.addEventListener("beforeunload", (e) => {
            if (!this.canLeavePage) {
                e.preventDefault()
                e.returnValue = ""
            }
        })
    },
    methods: {
        async saveDatasource() {
            if (!this.validateForm()) {
                return
            }

            try {
                const datasource = {
                    ...this.datasource,
                    name: this.datasource.name.trim()
                }

                if (!datasource.name) {
                    EventBus.emit("message", {
                        type: "danger",
                        message: "O nome do datasource é obrigatório!"
                    })
                    return
                }

                if (datasource.name.length > 50) {
                    EventBus.emit("message", {
                        type: "danger",
                        message: "O nome do datasource deve ter no máximo 50 caracteres!"
                    })
                    return
                }

                // new
                if (!this.$route.params.id) {
                    let saveResponse = await Api.datasources.create(datasource)
                    let id = saveResponse.data._id
                    this.$router.push("/datasources/edit/" + id)
                } else {
                    // update
                    await Api.datasources.update(datasource._id, datasource)
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "O datasource foi salvo com sucesso!"
                })

                this.canLeavePage = true

                // EventBus.emit('update-datasources-list')
            } catch (e) {
                var message = e.message
                if (e.response && e.response.data && e.response.data.message) {
                    message = e.response.data.message
                }

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar o datasource: " + $t(message)
                })
            }
        },
        // code editor
        initQueryEditor: function () {
            if (window.monacoEditorQuery) {
                window.monacoEditorQuery.dispose()
            }

            var editor = monaco.editor.create(document.getElementById("monacoEditorContainerQuery"), {
                value: this.datasource.database_sql,
                theme: "vs-dark",
                language: "sql",
                automaticLayout: true,
                contextmenu: true,
                minimap: {
                    enabled: true
                },
                padding: {
                    top: 10
                }
            })

            editor.onDidChangeModelContent((e) => {
                this.datasource.database_sql = editor.getValue()
            })

            // set monaco editor to global variable to prevent memory leaks on Vue
            window.monacoEditorQuery = editor

            // register new global shortcut (Ctrl/CMD + Shift + P)
            window.addEventListener("keydown", (e) => {
                if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.code === "KeyP") {
                    this.showCommandPalette()
                }
            })
        },

        // code editor
        initTransformationEditor: function () {
            if (window.monacoEditorTransformation) {
                window.monacoEditorTransformation.dispose()
            }

            var editor = monaco.editor.create(document.getElementById("monacoEditorContainerTransformation"), {
                value: this.datasource.transformation_code,
                theme: "vs-dark",
                language: "javascript",
                automaticLayout: true,
                contextmenu: true,
                minimap: {
                    enabled: true
                },
                padding: {
                    top: 10
                }
            })

            editor.onDidChangeModelContent((e) => {
                this.datasource.transformation_code = editor.getValue()
            })

            // set monaco editor to global variable to prevent memory leaks on Vue
            window.monacoEditorTransformation = editor

            // register new global shortcut (Ctrl/CMD + Shift + P)
            window.addEventListener("keydown", (e) => {
                if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.code === "KeyP") {
                    this.showCommandPalette()
                }
            })
        },

        showCommandPalette() {
            window.monacoEditorQuery.focus()
            window.monacoEditorQuery.trigger("", "editor.action.quickCommand", "")
        },

        removeVariable(index) {
            this.datasource.input_variables.splice(index, 1)
        },

        addVariable() {
            if (!this.datasource.input_variables) {
                this.datasource.input_variables = []
            }
            this.datasource.input_variables.push({})
        },

        validateForm() {
            let isValid = true

            let formContainer = document.querySelector(".form-datasource")
            let requiredFields = formContainer.querySelectorAll("input.required, select.required, textarea.required")
            let queryEditorErrorMessage = document.querySelector(".query-editor-error-message")
            let transformationCodeErrorMessage = document.querySelector(".transformation-code-error-message")

            queryEditorErrorMessage.style.display = "none"
            transformationCodeErrorMessage.style.display = "none"

            requiredFields.forEach((field) => {
                if (!field.value.trim()) {
                    field.classList.add("is-invalid")
                    isValid = false
                } else {
                    field.classList.remove("is-invalid")
                }
            })

            // validate SQL editor content
            if (!this.datasource.database_sql) {
                isValid = false
                queryEditorErrorMessage.style.display = "block"
                EventBus.emit("message", {
                    type: "danger",
                    message: "A consulta SQL é obrigatória"
                })
            }

            // validate transformation code editor content
            if (!this.datasource.transformation_code) {
                isValid = false
                transformationCodeErrorMessage.style.display = "block"
                EventBus.emit("message", {
                    type: "danger",
                    message: "O código de transformação de dados é obrigatório"
                })
            }

            return isValid
        },

        validateInputVariables() {
            let isValid = true

            let inputVariables = this.datasource.input_variables

            if (inputVariables && inputVariables.length > 0) {
                inputVariables.forEach((variable) => {
                    let input = document.querySelector(`input[name="$input-variable-${variable.key}"]`)

                    if (variable.required && !input.value.trim()) {
                        input.classList.add("is-invalid")
                        isValid = false
                    } else {
                        input.classList.remove("is-invalid")
                    }
                })
            }

            return isValid
        },

        async callExecuteQuery() {
            if (!this.validateForm()) {
                return
            }

            if (this.datasource.input_variables && this.datasource.input_variables.length > 0) {
                this.modalInputVariables.show()
            } else {
                await this.executeQuery()
            }
        },

        async executeQuery() {
            if (!this.validateForm()) {
                return
            }

            if (!this.validateInputVariables()) {
                return
            }

            let variablesData = {}
            if (this.datasource.input_variables) {
                this.datasource.input_variables.forEach((variable) => {
                    let input = document.querySelector(`input[name="$input-variable-${variable.key}"]`)
                    variablesData[variable.key] = input.value
                })
            }

            this.modalInputVariables.hide()

            const type = this.datasource.type
            const serviceAccountId = this.datasource.service_account
            const query = this.datasource.database_sql
            const transformationCode = this.datasource.transformation_code
            const id = this.$route?.params?.id || null

            let queryResponse = await EngineApi.datasources.executeQuery(
                type,
                serviceAccountId,
                variablesData,
                query,
                transformationCode,
                id
            )

            this.queryResult = queryResponse.data?.result || []
            this.queryErrorMessage = $t(queryResponse.data?.message) || ""
            this.queryResultKey = Math.random()

            // scroll page to result
            setTimeout(() => {
                document.querySelector(".datasource-result").scrollIntoView({ behavior: "smooth" })
            }, 200)
        }
    }
}
</script>

<style>
.table-variables td,
.table-variables th {
    padding: 3px;
    vertical-align: middle;
}
.table-variables td {
    text-align: center;
}
</style>
